import React from 'react'

const IconLinkedIn = ({
  width = 18,
  height = 18,
  fill = 'currentColor',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        fill={fill}
        d="M15.337 15.337H12.67v-4.176c0-.996-.018-2.279-1.387-2.279-1.39 0-1.602 1.085-1.602 2.206v4.25H7.014v-8.59h2.56V7.92h.037a2.81 2.81 0 012.526-1.387c2.703 0 3.201 1.778 3.201 4.092l-.001 4.711zM4.005 5.574c-.85 0-1.548-.699-1.548-1.548 0-.85.699-1.548 1.548-1.548.849 0 1.547.699 1.547 1.548 0 .849-.698 1.548-1.547 1.548zm1.333 9.763h-2.67v-8.59h2.67v8.59zM16.667.001H1.328A1.32 1.32 0 000 1.3V16.7A1.32 1.32 0 001.328 18h15.339A1.323 1.323 0 0018 16.7V1.298A1.322 1.322 0 0016.667 0"
      />
    </svg>
  )
}

export default IconLinkedIn
