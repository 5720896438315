import React from 'react'

const IconYouTube = ({
  width = 18,
  height = 13,
  fill = 'currentColor',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 13"
      aria-hidden="true"
      focusable="false"
      fill="none"
      {...svgAttributes}
    >
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.047.996c.28.288.479.645.577 1.034C18 3.479 18 6.5 18 6.5s0 3.021-.376 4.47a2.293 2.293 0 01-1.592 1.642C14.63 13 9 13 9 13s-5.63 0-7.032-.388A2.292 2.292 0 01.376 10.97C0 9.521 0 6.5 0 6.5s0-3.021.376-4.47A2.292 2.292 0 011.968.388C3.37 0 9 0 9 0s5.63 0 7.032.388c.386.11.736.32 1.015.608zM12.08 6.5L7.29 9.243V3.756L12.08 6.5z"
      />
    </svg>
  )
}

export default IconYouTube
