import styled from 'styled-components'
import { font } from '../../styles/vars/font.style'
import { colors } from '../../styles/vars/colors.style'
import { easings } from '../../styles/vars/easings.style'
import { mq } from '../../styles/vars/media-queries.style'
import { PageTransitionLinkMain } from '../PageTransitionLink/index.style'

export const FooterMain = styled.footer`
  color: ${colors.lightGrey};
  background-color: ${colors.darkGrey};
`

export const FooterWrapper = styled.div`
  position: relative;
`

export const FooterMonogram = styled.div`
  padding-bottom: 1.6rem;
`

export const FooterHeading = styled.h4`
  margin-bottom: 0.5rem;
  font-size: 1.4rem;
  font-weight: ${font.primary.weight.medium};
  line-height: 1.35;
  color: ${colors.midGrey};
  text-transform: uppercase;

  * + & {
    margin-top: 2.1rem;
  }
`

export const FooterText = styled.p`
  font-size: 1.6rem;
  font-weight: ${font.primary.weight.regular};
  line-height: 2;
  letter-spacing: -0.01em;
  color: ${colors.lightGrey};

  a {
    color: ${colors.lightGrey};
    border-bottom: none;
  }
`

export const FooterLinks = styled.ul`
  list-style: none;
`

export const FooterLinksItem = styled.li`
  ${PageTransitionLinkMain} {
    position: relative;

    /* :after {
      content: '';
      position: absolute;
      top: calc(100% + 0rem);
      right: 0;
      left: 0;
      transform: scaleX(0);
      transform-origin: 100% 50%;
      display: block;
      height: 0.1rem;
      background-color: ${colors.lightBlue};
      transition: transform 0.15s ${easings.inOut.default} 0s;
    }

    &:hover {
      :after {
        transform: scaleX(1);
        transform-origin: 0% 50%;
      }
    } */
  }
`

export const FooterLink = styled.a`
  display: flex;
  align-items: center;
  color: ${colors.lightGrey};
  border: none;

  svg {
    margin-right: 1rem;
  }
`

export const FooterLanguageLink = styled.a`
  display: inline-flex;
  border-color: ${props => (props.active ? colors.blue : 'transparent')};

  & + & {
    margin-left: 1.5rem;
  }
`

export const FooterCopyrightWrapper = styled.div`
  ${mq.tabletL} {
    display: flex;
    align-items: flex-end;
  }
`

export const FooterCopyright = styled.p`
  font-size: 1.4rem;
  line-height: 1.35;
  letter-spacing: -0.01em;
  color: ${colors.midGrey};

  & + & {
    ${mq.tabletL} {
      margin-left: 2.2rem;
    }
  }

  a {
    color: currentColor;
  }
`

export const FooterBackToTop = styled.button`
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  border: none;
  background: none;

  ${mq.tabletL} {
    top: 0;
    bottom: auto;
  }
`
