import React from 'react'
import { colors } from '../../../styles/vars/colors.style'

const IconBackToTop = ({
  width = 45,
  height = 45,
  fill = colors.midGrey,
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45 45"
      aria-hidden="true"
      focusable="false"
      fill="none"
      {...svgAttributes}
    >
      <circle stroke={colors.midGrey} opacity=".5" cx="23" cy="23" r="21.5" />
      <path
        fill={colors.lightGrey}
        d="M24 25h.5-.5zm-.354-9.354a.5.5 0 01.708 0l3.181 3.182a.5.5 0 11-.707.707L24 16.707l-2.828 2.828a.5.5 0 11-.707-.707l3.181-3.182zM21.5 27.5V27v.5zm2-2.5v-9h1v9h-1zm1 0c0 .497-.145 1.23-.594 1.855-.468.65-1.243 1.145-2.406 1.145v-1c.837 0 1.312-.338 1.594-.73.3-.417.406-.933.406-1.27h1zm-3 3H18v-1h3.5v1z"
      />
    </svg>
  )
}

export default IconBackToTop
