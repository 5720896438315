import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Container from '../Container'
import Grid from '../Grid'
import GridItem from '../GridItem'
import Spacer from '../Spacer'
import Monogram from '../svgs/Monogram'
import PageTransitionLink from '../PageTransitionLink'
import AnimateSlideIn from '../animation/AnimateSlideIn'
import AnimateSplitText from '../animation/AnimateSplitText'
import {
  FooterHeading,
  FooterLinksItem,
  FooterLinks,
  FooterMain,
  FooterMonogram,
  FooterText,
  FooterLink,
  FooterLanguageLink,
  FooterCopyright,
  FooterWrapper,
  FooterBackToTop,
  FooterCopyrightWrapper,
} from './index.style'
import ScrollSection from '../ScrollSection'
import IconInstagram from '../svgs/IconInstagram'
import IconFacebook from '../svgs/IconFacebook'
import IconYouTube from '../svgs/IconYouTube'
import IconLinkedIn from '../svgs/IconLinkedIn'
import IconBackToTop from '../svgs/IconBackToTop'
import { scrollTo } from '../../utils/utils'

const Footer = () => {
  const {
    site: {
      siteMetadata: {
        siteUrlEN,
        siteUrlES,
        siteUrlMX,
        siteUrlFR,
        siteUrlIL,
        siteLocale,
        routes,
      },
    },
    contentfulFooter: {
      madridTelephoneNumber,
      miamiTelephoneNumber,
      costaRicaTelephoneNumber,
      franceTelephoneNumber,
      linksLabelText,
      followUsLabelText,
      getInTouchLabelText,
      languageLabelText,
      contactEmailAddress,
      instagramUrl,
      facebookUrl,
      youTubeUrl,
      linkedInUrl,
    },
    allContentfulPrivacyPolicy,
    contentfulHelpfulVideos: { seoTitle: helpfulVideosTitle },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrlEN
          siteUrlES
          siteUrlMX
          siteUrlFR
          siteUrlIL
          siteLocale
          routes {
            helpfulVideos
            privacyPolicy
          }
        }
      }
      contentfulFooter {
        madridTelephoneNumber
        miamiTelephoneNumber
        costaRicaTelephoneNumber
        franceTelephoneNumber
        linksLabelText
        followUsLabelText
        getInTouchLabelText
        languageLabelText
        contactEmailAddress
        instagramUrl
        facebookUrl
        youTubeUrl
        linkedInUrl
      }
      contentfulHelpfulVideos {
        seoTitle
      }
      allContentfulPrivacyPolicy {
        nodes {
          seoTitle
          slug
        }
      }
    }
  `)
  const year = new Date().getFullYear()

  return (
    <ScrollSection>
      <FooterMain>
        <Spacer size={[40, 90]} />

        <Container>
          <FooterWrapper>
            <Grid>
              <GridItem tabletL={1}>
                <AnimateSlideIn>
                  <FooterMonogram>
                    <Monogram width={32} height={60} />
                  </FooterMonogram>
                </AnimateSlideIn>
              </GridItem>

              <GridItem tiny={6} tabletP={3} tabletL={2}>
                <FooterHeading>
                  <AnimateSlideIn>Madrid</AnimateSlideIn>
                </FooterHeading>
                <FooterText>
                  <AnimateSlideIn>{madridTelephoneNumber}</AnimateSlideIn>
                </FooterText>

                <FooterHeading>
                  <AnimateSlideIn>Miami</AnimateSlideIn>
                </FooterHeading>
                <FooterText>
                  <AnimateSlideIn>{miamiTelephoneNumber}</AnimateSlideIn>
                </FooterText>

                <FooterHeading>
                  <AnimateSlideIn>Costa Rica</AnimateSlideIn>
                </FooterHeading>
                <FooterText>
                  <AnimateSlideIn>{costaRicaTelephoneNumber}</AnimateSlideIn>
                </FooterText>

                <FooterHeading>
                  <AnimateSlideIn delay={0.4}>France</AnimateSlideIn>
                </FooterHeading>
                <FooterText>
                  <AnimateSlideIn delay={0.5}>
                    {franceTelephoneNumber}
                  </AnimateSlideIn>
                </FooterText>

                <Spacer size={16} />
              </GridItem>

              <GridItem tiny={6} tabletP={3} tabletL={2}>
                <FooterHeading>
                  <AnimateSlideIn>{linksLabelText}</AnimateSlideIn>
                </FooterHeading>

                <FooterLinks>
                  <FooterLinksItem>
                    <AnimateSlideIn>
                      <PageTransitionLink to={routes.helpfulVideos}>
                        <FooterText>{helpfulVideosTitle}</FooterText>
                      </PageTransitionLink>
                    </AnimateSlideIn>
                  </FooterLinksItem>

                  {allContentfulPrivacyPolicy.nodes.map((item, index) => {
                    return (
                      <FooterLinksItem key={index}>
                        <AnimateSlideIn>
                          <PageTransitionLink to={`/` + item.slug}>
                            <FooterText>{item.seoTitle}</FooterText>
                          </PageTransitionLink>
                        </AnimateSlideIn>
                      </FooterLinksItem>
                    )
                  })}
                </FooterLinks>
              </GridItem>

              <GridItem tiny={6} tabletP={3} tabletL={2}>
                <FooterHeading>
                  <AnimateSlideIn>{followUsLabelText}</AnimateSlideIn>
                </FooterHeading>

                {instagramUrl && (
                  <AnimateSlideIn>
                    <FooterLink
                      href={instagramUrl}
                      target="_blank"
                      rel="noopener"
                    >
                      <IconInstagram /> <FooterText>Instagram</FooterText>
                    </FooterLink>
                  </AnimateSlideIn>
                )}

                {facebookUrl && (
                  <AnimateSlideIn>
                    <FooterLink
                      href={facebookUrl}
                      target="_blank"
                      rel="noopener"
                    >
                      <IconFacebook /> <FooterText>Facebook</FooterText>
                    </FooterLink>
                  </AnimateSlideIn>
                )}

                {youTubeUrl && (
                  <AnimateSlideIn>
                    <FooterLink
                      href={youTubeUrl}
                      target="_blank"
                      rel="noopener"
                    >
                      <IconYouTube /> <FooterText>YouTube</FooterText>
                    </FooterLink>
                  </AnimateSlideIn>
                )}

                {linkedInUrl && (
                  <AnimateSlideIn>
                    <FooterLink
                      href={linkedInUrl}
                      target="_blank"
                      rel="noopener"
                    >
                      <IconLinkedIn /> <FooterText>LinkedIn</FooterText>
                    </FooterLink>
                  </AnimateSlideIn>
                )}
              </GridItem>

              <GridItem tiny={6} tabletP={3} tabletL={2}>
                <FooterHeading>
                  <AnimateSlideIn>{getInTouchLabelText}</AnimateSlideIn>
                </FooterHeading>
                <FooterText>
                  <AnimateSlideIn>
                    <a href={`mailto:${contactEmailAddress}`}>
                      {contactEmailAddress}
                    </a>
                  </AnimateSlideIn>
                </FooterText>

                <FooterHeading>
                  <AnimateSlideIn>{languageLabelText}</AnimateSlideIn>
                </FooterHeading>

                <FooterLanguageLink
                  href={siteUrlEN}
                  active={siteLocale === 'en-US'}
                >
                  <AnimateSlideIn>
                    <FooterText>EN</FooterText>
                  </AnimateSlideIn>
                </FooterLanguageLink>

                <FooterLanguageLink
                  href={siteUrlES}
                  active={siteLocale === 'es'}
                >
                  <AnimateSlideIn>
                    <FooterText>ES</FooterText>
                  </AnimateSlideIn>
                </FooterLanguageLink>

                <FooterLanguageLink href={siteUrlMX}>
                  <AnimateSlideIn>
                    <FooterText>MX</FooterText>
                  </AnimateSlideIn>
                </FooterLanguageLink>

                <FooterLanguageLink
                  href={siteUrlFR}
                  active={siteLocale === 'fr'}
                >
                  <AnimateSlideIn delay={0.4}>
                    <FooterText>FR</FooterText>
                  </AnimateSlideIn>
                </FooterLanguageLink>

                <FooterLanguageLink
                  href={siteUrlIL}
                  active={siteLocale === 'he-IL'}
                >
                  <AnimateSlideIn delay={0.4}>
                    <FooterText>IL</FooterText>
                  </AnimateSlideIn>
                </FooterLanguageLink>
              </GridItem>

              <GridItem>
                <Spacer size={[36, 48]} />

                <FooterCopyrightWrapper>
                  <FooterCopyright>
                    <AnimateSplitText>
                      {`&copy; ${year} Viax Dental Technologies`}
                    </AnimateSplitText>
                  </FooterCopyright>

                  <FooterCopyright>
                    <AnimateSplitText>
                      Site by{' '}
                      <a
                        href="https://toyfight.co/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        ToyFight
                      </a>
                    </AnimateSplitText>
                  </FooterCopyright>
                </FooterCopyrightWrapper>
              </GridItem>
            </Grid>

            <FooterBackToTop
              onClick={() => scrollTo(0)}
              aria-label="Back to top"
            >
              <AnimateSlideIn>
                <IconBackToTop />
              </AnimateSlideIn>
            </FooterBackToTop>
          </FooterWrapper>
        </Container>

        <Spacer size={[58, 80]} />
      </FooterMain>
    </ScrollSection>
  )
}

export default Footer
