import React from 'react'

const IconInstagram = ({
  width = 18,
  height = 18,
  fill = 'currentColor',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        fill={fill}
        d="M5.225 0A5.235 5.235 0 000 5.225v7.55A5.235 5.235 0 005.225 18h7.55A5.235 5.235 0 0018 12.775v-7.55A5.235 5.235 0 0012.775 0h-7.55zm0 1.385h7.55c2.128 0 3.84 1.709 3.84 3.84v7.55c0 2.128-1.709 3.84-3.84 3.84h-7.55a3.829 3.829 0 01-3.84-3.84v-7.55c0-2.129 1.709-3.84 3.84-3.84zm8.967 1.384a1.039 1.039 0 100 2.078 1.039 1.039 0 000-2.078zM9 4.154A4.856 4.856 0 004.154 9 4.856 4.856 0 009 13.846 4.856 4.856 0 0013.846 9 4.856 4.856 0 009 4.154zm0 1.384A3.45 3.45 0 0112.461 9 3.45 3.45 0 019 12.461 3.45 3.45 0 015.538 9 3.451 3.451 0 019 5.538z"
      />
    </svg>
  )
}

export default IconInstagram
