import React from 'react'
import monogramBackground from './monogram-background.jpg'

const Monogram = ({ width = 43, height = 82, responsive = false }) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }
  const id = `monogramMask-${Math.random() * 1000}`

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 43 82"
      aria-hidden="true"
      focusable="false"
      fill="none"
      {...svgAttributes}
    >
      <defs>
        <mask id={id}>
          <path
            fill="white"
            d="M42.343 39.542L23.069 28.591a2.53 2.53 0 00-.505-.213c.257-3.722 3.442-5.585 3.442-5.585l16.337-9.252a1.29 1.29 0 000-2.247L23.073.343a2.606 2.606 0 00-2.576-.004L3.467 9.982S0 11.95 0 16.035V61.98c0 .928.5 1.784 1.311 2.248l2.593 1.474v2.924c0 .927.5 1.783 1.31 2.247l19.283 10.956c.21.117.429.171.643.171.68 0 1.303-.539 1.303-1.295V54.888c0-4.102 3.466-6.052 3.466-6.052l12.43-7.047a1.29 1.29 0 00.004-2.247zM2.118 62.82a.97.97 0 01-.492-.844V16.031c0-3.058 2.547-4.59 2.643-4.65l17.026-9.64a.98.98 0 01.967.005l18.782 10.672-15.858 8.98c-.168.1-4.02 2.393-4.26 7-.152.05-.299.113-.437.193L7.366 36.025s-3.467 1.964-3.467 6.053v21.757L2.118 62.82zm18.8-32.613V73.5L5.53 64.758V42.073c0-3.061 2.546-4.59 2.647-4.648l12.74-7.218zm8.193 17.217c-.177.1-4.29 2.473-4.29 7.46V80.14l-18.8-10.68a.97.97 0 01-.491-.844v-1.996l15.064 8.562a1.297 1.297 0 001.946-1.124V30.154l18.5 10.513-11.929 6.758z"
          />
        </mask>
      </defs>

      <image href={monogramBackground} mask={`url(#${id})`} width="100%" />
    </svg>
  )
}

export default Monogram
