import React from 'react'

const IconFacebook = ({
  width = 18,
  height = 18,
  fill = 'currentColor',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        fill={fill}
        d="M10.546 0c-2.26 0-4.091 1.919-4.091 4.286v2.143H4v3.428h2.455V18h3.272V9.857h2.864L13 6.43H9.727V4.714c0-.946.733-1.714 1.637-1.714H13V.138A23.366 23.366 0 0010.546 0z"
      />
    </svg>
  )
}

export default IconFacebook
